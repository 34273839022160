import React from "react";
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { images } from "../../constants";
import "./Gallery.css";

const Gallery = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = React.useRef(null);
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/valentines-collection");
  };

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center" id="gallery">
      <div className="app__gallery-content">
        <h1
          className="headtext__cormorant"
          style={{
            textAlign: "center",
            marginBottom: isMobile ? "-30px" : "10px",
          }}
        >
          Featured Products
        </h1>
        <p
          className="gallery__christmas-collection"
          style={{
            color: "#575756",
            fontSize: isMobile ? "14px" : "20px",
            fontWeight: "bold",
            margin: "20px auto",
            textAlign: "center",
            marginTop: isMobile ? "40px" : "",
            marginBottom: isMobile ? "-40px" : "",
          }}
        >
          💌 Check out our exclusive Valentines Collection! 🏹❣️
        </p>
        {!isMobile && (
          <button
            type="button"
            className="custom__button"
            style={{ display: "block", margin: "20px auto" }}
            onClick={handleViewMoreClick}
          >
            View More
          </button>
        )}
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.id1, images.id21, images.id9, images.id11].map(
            (image, index) => (
              <div
                className="app__gallery-images_card flex__center"
                key={`gallery_image-${index + 1}`}
              >
                <img src={image} alt="gallery_image" loading="lazy" />
                <BsInstagram className="gallery__image-icon" />
              </div>
            )
          )}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            color="#7D839D"
            className="gallery__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            color="#7D839D"
            className="gallery__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
      {isMobile && (
        <button
          type="button"
          className="custom__button"
          style={{ display: "block", margin: "20px auto", marginTop: "-30px" }}
          onClick={handleViewMoreClick}
        >
          View More
        </button>
      )}
    </div>
  );
};

export default Gallery;
