import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.memoraLogo} alt="app__logo" loading="lazy" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Home</a>
        </li>
        <li className="p__opensans">
          <a href="#about">About</a>
        </li>
        <li className="p__opensans app__navbar-dropdown">
          <a href="#collections">Collections</a>
          <ul className="app__navbar-dropdown-menu">
            <li className="p__opensans">
              <button onClick={() => handleNavigation("/valentines-collection")}>
                Valentines
              </button>
            </li>
            <li className="p__opensans">
              <button onClick={() => handleNavigation("/christmas-collection")}>
                Christmas
              </button>
            </li>
          </ul>
        </li>
        <li className="p__opensans">
          <a href="#awards">Offerings</a>
        </li>
        <li className="p__opensans">
          <a href="#gallery">Products</a>
        </li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#575756"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <IoCloseSharp
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
              style={{ marginTop: "50px", marginRight: "15px" }}
            />
            <ul className="app__navbar-smallscreen_links">
              <li>
                <a href="#home" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="#about" onClick={() => setToggleMenu(false)}>
                  About
                </a>
              </li>
              <li className="p__opensans app__navbar-dropdown">
          <a href="#collections">Collections</a>
          <ul className="app__navbar-dropdown-menu">
            <li>
              <button onClick={() => handleNavigation("/valentines-collection")}>
                Valentines
              </button>
            </li>
            <li>
              <button onClick={() => handleNavigation("/christmas-collection")}>
                Christmas
              </button>
            </li>
          </ul>
        </li>
              <li>
                <a href="#awards" onClick={() => setToggleMenu(false)}>
                  Offerings
                </a>
              </li>
              <li>
                <a href="#gallery" onClick={() => setToggleMenu(false)}>
                  Products
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;