import React, { useState, useLayoutEffect } from "react";
import {
  Modal,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Pagination,
  Box,
  Avatar,
  TextField,
  Slider,
  IconButton,
  Badge,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InfoIcon from "@mui/icons-material/Info";
import { featuredProducts, ValentinesProducts } from "../../constants/data";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { images } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "react-image-lightbox/style.css";

const Products = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [priceFilter, setPriceFilter] = useState([0, 100]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [customPrice, setCustomPrice] = useState(50);

  const [selectedWines, setSelectedWines] = useState({});

  const [isOpen, setIsOpen] = useState(false); // Lightbox open state
  const [photoIndex, setPhotoIndex] = useState(0); // Current image index
  const [selectedProduct, setSelectedProduct] = useState(null); // Currently selected product

  // Function to handle image click
  const handleImageClick = (product, index) => {
    setSelectedProduct(product); // Set the selected product when clicking on an image
    setPhotoIndex(index); // Set the correct image index
    setIsOpen(true); // Open the lightbox
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleChange = (productId) => (event) => {
    setSelectedWines({
      ...selectedWines,
      [productId]: event.target.value,
    });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]); // Add currentPage as a dependency

  const whatsappNumber = "+96181367567";
  const itemsPerPage = 8;

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleAddressChange = (e) => setAddress(e.target.value);

  const isFormValid = firstName !== "" && lastName !== "";

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceFilter(newValue);
  };

  const handleToggleDelivery = () => {
    setDelivery((prevDelivery) => !prevDelivery);
  };

  const handleAddToCart = (product) => {
    const priceToUse =
      product.id === 20 && customPrice ? customPrice : product.price;

    const existingProductIndex = cart.findIndex(
      (item) => item.id === product.id
    );

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += 1;
      updatedCart[existingProductIndex].price = priceToUse;
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...product, quantity: 1, price: priceToUse }]);
    }

    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const handleRemoveFromCart = (productId) => {
    const updatedCart = cart
      .map((item) =>
        item.id === productId
          ? { ...item, quantity: item.quantity > 1 ? item.quantity - 1 : 0 }
          : item
      )
      .filter((item) => item.quantity > 0);

    setCart(updatedCart);
  };

  const handleAddQuantity = (productId) => {
    const updatedCart = cart.map((item) =>
      item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCart(updatedCart);
  };

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

  const convertPriceToNumber = (priceString) => {
    return parseFloat(priceString.replace("$", "").replace(",", ""));
  };

  const filteredNormalProducts = ValentinesProducts.filter((product) => {
    const matchesName = product.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const price = convertPriceToNumber(product.price);
    const matchesPrice = price >= priceFilter[0] && price <= priceFilter[1];

    const matchesCategory =
      categoryFilter.length === 0 || categoryFilter.includes(product.category);

    return matchesName && matchesPrice && matchesCategory;
  }).slice(indexOfFirstProduct, indexOfLastProduct);

  const productsToShow =
    searchQuery ||
    priceFilter[0] !== 0 ||
    priceFilter[1] !== 100 ||
    categoryFilter.length > 0
      ? filteredNormalProducts
      : ValentinesProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const calculateTotalPrice = () => {
    return cart.reduce(
      (total, item) => total + convertPriceToNumber(item.price) * item.quantity,
      0
    );
  };

  const formatCartMessage = () => {
    const cartItemsMessage = cart
      .map((item) => `${item.name}\n$${item.price} x ${item.quantity}`)
      .join("\n\n");

    const totalPrice = calculateTotalPrice().toFixed(2);
    const orderType = delivery ? "Delivery" : "Pick up";

    const wineMessage =
      selectedWines && Object.keys(selectedWines).length
        ? `\n\nSelected Wines:\n${Object.entries(selectedWines)
            .map(([productId, wineName]) => `- ${wineName.label}`)
            .join("\n")}`
        : "";

    return (
      `Here's my order (${orderType}):\n\n` +
      `Name: ${firstName} ${lastName}\n` +
      `Address: ${address || "Not provided"}\n\n` +
      `${cartItemsMessage}${wineMessage}\n\nTotal: $${totalPrice}`
    );
  };

  const openWhatsAppCheckout = () => {
    const message = encodeURIComponent(formatCartMessage());
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleLearnMore = (product) => {
    const message = encodeURIComponent(
      `I want to learn more about "${product.name}"`
    );
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#FFFFFF", // White background
      color: "#A794C6", // Text color
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "8px",
      boxShadow: theme.shadows[3],
      padding: "10px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "#FFFFFF", // Arrow matches the background
    },
  }));

  return (
    <Box
      sx={{
        padding: { xs: "16px", sm: "32px", marginTop: "50px" },
        backgroundColor: "#f5f5f5",
      }}
    >
      <CustomTooltip
        open={tooltipOpen}
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "#575756",
            }}
          >
            <ShoppingCartIcon style={{ fontSize: "20px", color: "#A794C6" }} />
            <span>Item was added to your cart!</span>
          </div>
        }
        arrow
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton
          onClick={() => setIsCartOpen(!isCartOpen)}
          sx={{
            position: "fixed",
            top: "25px",
            right: "30px",
            backgroundColor: "#A794C6",
            "&:hover": { backgroundColor: "#9D83A6" },
            zIndex: 999,
          }}
        >
          <Badge
            badgeContent={cart.length}
            color="primary"
            style={{ zIndex: 999 }}
          >
            <ShoppingCartIcon sx={{ color: "white", zIndex: 999 }} />
          </Badge>
        </IconButton>
      </CustomTooltip>
      {isCartOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "300px",
            height: "100%",
            backgroundColor: "#fff",
            boxShadow: "-4px 0px 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            overflowY: "auto",
            transform: isCartOpen ? "translateX(0)" : "translateX(100%)",
            transition: "transform 0.3s ease",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h6" sx={{ color: "#A794C6", fontWeight: 700 }}>
              My Cart
            </Typography>
            <IconButton onClick={() => setIsCartOpen(false)}>
              <CloseIcon sx={{ color: "#A794C6" }} />
            </IconButton>
          </Box>
          {cart.length === 0 ? (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", width: "100%" }}
            >
              Your cart is empty.
            </Typography>
          ) : (
            <>
              {cart.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <Box>
                    <Typography variant="body1">{item.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      ${convertPriceToNumber(item.price)} x {item.quantity}
                    </Typography>
                  </Box>
                  <IconButton onClick={() => handleRemoveFromCart(item.id)}>
                    <RemoveIcon sx={{ color: "#A794C6" }} />
                  </IconButton>
                  <IconButton onClick={() => handleAddQuantity(item.id)}>
                    <AddIcon sx={{ color: "#A794C6" }} />
                  </IconButton>
                </Box>
              ))}

              <Box sx={{ marginTop: "16px" }}>
                <TextField
                  label="First Name"
                  required
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={handleFirstNameChange}
                  sx={{
                    marginBottom: "8px",
                    "& .MuiInputLabel-root": {
                      fontSize: "14px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": { borderColor: "#A794C6" },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#575756", fontSize: "14px" },
                  }}
                />
                <TextField
                  label="Last Name"
                  required
                  variant="outlined"
                  fullWidth
                  value={lastName}
                  onChange={handleLastNameChange}
                  sx={{
                    marginBottom: "8px",
                    "& .MuiInputLabel-root": {
                      fontSize: "14px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": { borderColor: "#A794C6" },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#575756", fontSize: "14px" },
                  }}
                />
              </Box>

              <Box sx={{ marginTop: "8px" }}>
                <RadioGroup
                  value={delivery ? "delivery" : "pickup"}
                  onChange={(e) =>
                    handleToggleDelivery(e.target.value === "delivery")
                  }
                  row
                >
                  <FormControlLabel
                    value="pickup"
                    control={
                      <Radio
                        sx={{
                          color: "#A794C6",
                          "&.Mui-checked": { color: "#A794C6" },
                          "& .MuiSvgIcon-root": {
                            fontSize: 22,
                          },
                        }}
                      />
                    }
                    label="Pickup"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="delivery"
                    control={
                      <Radio
                        sx={{
                          color: "#A794C6",
                          "&.Mui-checked": { color: "#A794C6" },
                          "& .MuiSvgIcon-root": {
                            fontSize: 22,
                          },
                        }}
                      />
                    }
                    label="Delivery"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                      },
                    }}
                  />
                </RadioGroup>
              </Box>
              {delivery && (
                <TextField
                  label="Address (Optional)"
                  variant="outlined"
                  fullWidth
                  value={address}
                  onChange={handleAddressChange}
                  sx={{
                    marginBottom: "16px",
                    marginTop: "16px",
                    "& .MuiInputLabel-root": {
                      fontSize: "14px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": { borderColor: "#A794C6" },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#575756", fontSize: "14px" },
                  }}
                />
              )}

              <Box sx={{ borderTop: "1px solid #ccc", paddingTop: "16px" }}>
                <Typography variant="h6" sx={{ fontSize: "18px" }}>
                  Total: ${calculateTotalPrice().toFixed(2)}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#A794C6",
                    "&:hover": { backgroundColor: "#9D83A6" },
                    width: "100%",
                    marginTop: "16px",
                  }}
                  onClick={openWhatsAppCheckout}
                  disabled={!isFormValid}
                >
                  Checkout
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}

      {/* <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: "#A794C6",
          fontWeight: 700,
          fontFamily: '"Merriweather", serif',
          fontSize: "22px",
        }}
      >
        Featured Items
        <Avatar
          alt="Logo"
          src={images.greyAmblem}
          sx={{ width: 30, height: 30, marginLeft: 2, display: "inline-block" }}
        />
      </Typography>

      <Box sx={{ position: "relative", overflow: "hidden", width: "100%" }}>
        {useMediaQuery(theme.breakpoints.up("sm")) && (
          <>
            <IconButton
              onClick={handlePrev}
              sx={{
                position: "absolute",
                top: "50%",
                left: 0,
                zIndex: 2,
                transform: "translateY(-50%)",
              }}
            >
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                top: "50%",
                right: 0,
                zIndex: 2,
                transform: "translateY(-50%)",
              }}
            >
            </IconButton>
          </>
        )}

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          centered
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            overflowX: "auto",
            "& .MuiTabs-flexContainer": { display: "flex" },
            "& .MuiTabs-indicator": {
              backgroundColor: "#A794C6",
            },
            "& .css-1mf9qzl-MuiButtonBase-root-MuiTabScrollButton-root": {
              color: 'transparent',
              backgroundColor: "transparent",
              outline: "none",
              "&:hover, &:active, &:focus": {
                backgroundColor: "transparent",
                outline: "none",
              },                     
              "& .MuiSvgIcon-root": {
                color: "#7D839D",
                pointerEvents: "none",
                fontSize: "2rem"
              },
            },
          }}
        >
          {featuredProducts.map((product) => (
            <Tab
              key={product.id}
              label={
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: 3,
                    width: isMobile ? 250 : 300,
                    height: isMobile ? 410 : "auto",
                  }}
                >
                  <CardMedia
                    component="img"
                    height={isMobile ? 260 : 300}
                    image={product.imageUrl}
                    alt={product.name}
                    sx={{
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                  <CardContent
                    sx={{
                      padding: isMobile ? "8px" : "16px",
                      display: "flex",
                      flexDirection: "column",
                      gap: isMobile ? "8px" : "12px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: isMobile ? "16px" : "20px",
                        color: "#7D839D",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: isMobile ? "12px" : "14px",
                        color: "#9D9D9C",
                        marginBottom: "8px",
                        textAlign: "center",
                      }}
                    >
                      {product.description}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        backgroundColor: "#A794C6",
                        "&:hover": { backgroundColor: "#9D83A6" },
                        fontSize: isMobile ? "12px" : "16px",
                        padding: isMobile ? "" : "8px 16px",
                      }}
                      onClick={() => handleAddToCart(product)}
                    >
                      Add to Cart
                    </Button>
                  </CardContent>
                </Card>
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  color: "transparent",
                },
                "&:focus": {
                  outline: "none",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            />
          ))}
        </Tabs>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#A794C6",
            fontWeight: 700,
            marginBottom: "16px",
            fontFamily: '"Merriweather", serif',
            fontSize: "22px",
          }}
        >
          All Items
          <Avatar
            alt="Logo"
            src={images.greyAmblem}
            sx={{
              width: 30,
              height: 30,
              marginLeft: 2,
              display: "inline-block",
            }}
          />
        </Typography>

        <TextField
          label="Search Products"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          sx={{ marginBottom: "16px" }}
        />

        <Typography
          variant="h6"
          sx={{
            color: "#A794C6",
            fontWeight: 600,
            marginBottom: "8px",
            fontFamily: '"Merriweather", serif',
          }}
        >
          Price Filter: ${priceFilter[0]} - ${priceFilter[1]}
        </Typography>
        <Slider
          value={priceFilter}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `$${value}`}
          min={0}
          max={100}
          sx={{
            color: "#A794C6",
            marginBottom: "16px",
          }}
        />
        <Grid container spacing={4}>
          {productsToShow.length > 0 ? (
            productsToShow.map((product) => (
              <>
                <Grid item xs={12} sm={3} key={product.id}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      borderRadius: 3,
                      height: isMobile ? "auto" : "560px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {product.imageUrl?.length > 1 ? (
                      <Swiper
                        navigation={true}
                        initialSlide={photoIndex} // Start at the clicked image
                        onSlideChange={(swiper) =>
                          setPhotoIndex(swiper.activeIndex)
                        } // Update the index when the slide changes
                        modules={[Navigation]}
                        style={{ width: "100%", height: "100%" }}
                      >
                        {product.imageUrl.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={image}
                              alt={product.name}
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => handleImageClick(product, index)} // Set product and image index
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    ) : (
                      <CardMedia
                        component="img"
                        height="280"
                        image={product.imageUrl[0]}
                        alt={product.name}
                        sx={{
                          objectFit: "cover",
                          width: "100%",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageClick(product, 0)} // Open the single image in full screen
                      />
                    )}

                    {isOpen && (
                      <Modal open={isOpen} onClose={handleCloseModal}>
                        <Box
                          sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            zIndex: 9999,
                          }}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: 16,
                              right: 16,
                              color: "white",
                              zIndex: 10000,
                            }}
                            onClick={handleCloseModal}
                          >
                            <CloseIcon sx={{ fontSize: 32 }} />
                          </IconButton>

                          {/* Show Swiper if product has multiple images */}
                          {selectedProduct.imageUrl.length > 1 ? (
                            <Swiper
                              navigation={true}
                              initialSlide={photoIndex} // Set the initial slide to the clicked index
                              onSlideChange={(swiper) =>
                                setPhotoIndex(swiper.activeIndex)
                              } // Update index when the slide changes
                              modules={[Navigation]}
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {selectedProduct.imageUrl.map((image, index) => (
                                <SwiperSlide key={index}>
                                  <img
                                    src={image}
                                    alt={`${selectedProduct.name} image ${
                                      index + 1
                                    }`}
                                    style={{
                                      objectFit: "contain",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          ) : (
                            // Show the single image if there is only one
                            <img
                              src={selectedProduct.imageUrl[photoIndex]}
                              alt="Full screen view"
                              style={{
                                objectFit: "contain",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          )}
                        </Box>
                      </Modal>
                    )}

                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        paddingBottom: "16px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#7D839D", fontWeight: 600 }}
                      >
                        {product.name}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          color: "#7D839D",
                          fontWeight: 700,
                          marginBottom: "8px",
                        }}
                      >
                        {product.tagline}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          color: "#9D9D9C",
                          marginBottom: "16px",
                          fontSize: "12.5px",
                        }}
                      >
                        {product.description}
                      </Typography>

                      {product.showOptions &&
                        product.wineOptions &&
                        !product.outOfStock && (
                          <FormControl fullWidth size="small">
                            <InputLabel
                              id="wine-options-label"
                              size="small"
                              sx={{
                                marginTop: 0,
                                "&.Mui-focused": {
                                  marginTop: -5,
                                },
                                "&.MuiInputLabel-shrink": {
                                  marginTop: 1,
                                },
                              }}
                            >
                              Select Wine
                            </InputLabel>
                            <Select
                              labelId={`wine-options-label-${product.id}`}
                              id={`wine-options-${product.id}`}
                              value={selectedWines[product.id] || ""}
                              onChange={handleChange(product.id)}
                            >
                              {product.wineOptions.map((wine, index) => (
                                <MenuItem
                                  key={index}
                                  value={wine}
                                  onClick={() =>
                                    product.id === 20 &&
                                    setCustomPrice(wine.price)
                                  }
                                >
                                  {wine.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                      <Divider sx={{ marginY: "12px" }} />
                      {!product.outOfStock ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: isMobile ? -1 : -2,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#A794C6", // Updated price color
                              marginRight: "8px",
                            }}
                          >
                            {product.id === 20
                              ? `$${customPrice}`
                              : `${product.price}`}
                          </Typography>

                          <IconButton
                            sx={{
                              ml: 1,
                              color: "#A794C6",
                              "&:hover": {
                                backgroundColor: "#E0D8E8",
                              },
                            }}
                            onClick={() => handleAddToCart(product)}
                          >
                            <AddShoppingCartIcon sx={{ fontSize: 26 }} />
                          </IconButton>

                          <IconButton
                            sx={{
                              ml: 1,
                              color: "#A794C6",
                              "&:hover": {
                                backgroundColor: "#E0D8E8",
                              },
                            }}
                            onClick={() => handleLearnMore(product)}
                          >
                            <InfoIcon sx={{ fontSize: 26 }} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: isMobile ? -1 : 1,
                          }}
                        >
                          <ErrorOutlineIcon
                            sx={{
                              fontSize: 20,
                              color: "#A794C6",
                              marginRight: 1,
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#A794C6",
                            }}
                          >
                            We're sorry! This item is currently out of stock.
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="text.secondary"
                align="center"
                sx={{ mt: 5 }}
              >
                No results found.
              </Typography>
            </Grid>
          )}
        </Grid>

        {/* Fullscreen Modal */}
      </Box>

      <Pagination
        count={Math.ceil(ValentinesProducts.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          "& .MuiPaginationItem-root": {
            color: "#A794C6 !important",
          },
          "& .Mui-selected": {
            backgroundColor: "#9D83A6 !important",
            color: "#ffffff !important",
          },
          "& .MuiPaginationItem-root:hover": {
            backgroundColor: "#D6C8E3 !important",
          },
        }}
      />
    </Box>
  );
};

export default Products;
