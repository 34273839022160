import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { AboutUs, Footer, Gallery, Header, Offerings, Word } from "./container";
import NavbarSecondary from "./container/Navbar2/NavbarSecondary";
import Navbar from "./container/Navbar/Navbar";
import Products from "./container/Products/Products";
import ProductsValentines from "./container/Products/ProductsValentines";
import Services from "./container/Services/Services";
import NotFound from "./container/NotFound/NotFound";
import "./App.css";

const App = () => {
  const location = useLocation();

  const NavbarComponent =
    location.pathname === "/christmas-collection" ||
    location.pathname === "/valentines-collection" ||
    (location.pathname === "/services" && location.pathname !== "/")
      ? NavbarSecondary
      : Navbar;

  return (
    <>
      {(location.pathname === "/" ||
        location.pathname === "/services" ||
        location.pathname === "/valentines-collection" ||
        location.pathname === "/christmas-collection") && <NavbarComponent />}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <AboutUs />
              <Gallery />
              <Offerings />
              <Word />
            </>
          }
        />
        <Route path="/valentines-collection" element={<ProductsValentines />} />
        <Route path="/christmas-collection" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {(location.pathname === "/" ||
        location.pathname === "/services" ||
        location.pathname === "/valentines-collection" ||
        location.pathname === "/christmas-collection") && <Footer />}
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
